import useInfiniteScroll from 'hooks/useInfiniteScroll';
import { ReactNode, useRef } from 'react';
import Spinner, { SpinnerLocation } from './Spinner';

export default function InfiniteScroll({
  onEndReached,
  children,
  hasNextPage,
  className
}: {
  onEndReached: () => void;
  children: ReactNode;
  hasNextPage?: boolean;
  className?: string;
}) {
  const el = useRef<HTMLDivElement>(null);

  useInfiniteScroll({
    ref: el,
    onEndReached
  });

  return (
    <div ref={el} className={className}>
      {children}
      {hasNextPage && <Spinner location={SpinnerLocation.SCROLL_FOOTER} />}
    </div>
  );
}
