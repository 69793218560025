import { useApplicationContainer } from 'containers/ApplicationContainer';
import { PHOTO_GALLERIES, usePhotoGalleriesContainer } from '..';
import PhotoGalleryTile from 'components/PhotoGalleryTile';
import { localizeDate } from 'components/LocalizedDate';
import PhotoGalleriesGrid from './PhotoGalleriesGrid';
import styles from './styles/PhotoGalleriesSportCollections.module.scss';
import AngleBracketRight from 'icons/AngleBracketRight';
import Link from 'components/Link';
import MinuteMediaAd, { MinuteMediaPlacementType } from '@/components/ads/MinuteMediaAd';

const adSlots: MinuteMediaPlacementType[] = ['IN_FEED', 'IN_FEED_2'];

export default function PhotoGallerySportCollections() {
  const [{ state }, { makePath }] = useApplicationContainer();
  const [{ collections }] = usePhotoGalleriesContainer();

  return (
    <div>
      {collections
        .filter(collection => collection.galleries.length)
        .map((collection, index) => {
          return (
            <div key={collection.label}>
              <div className={styles.CollectionTitle}>
                {collection.label}
                {collection.showAll && (
                  <Link
                    href={makePath({ state: state, genderSport: collection.galleries[0].genderSport, path: PHOTO_GALLERIES })}
                    className={styles.CollectionAll}
                  >
                    See All
                    <AngleBracketRight />
                  </Link>
                )}
              </div>
              <PhotoGalleriesGrid>
                {collection.galleries.map(({ id, coverPhoto, genderSport, teams, date, photoCount, photographer, webPath }) => (
                  <PhotoGalleryTile
                    key={id}
                    coverImage={coverPhoto.watermark}
                    genderSport={genderSport?.key ?? ''}
                    teams={teams}
                    dateText={localizeDate(date, 'MMMM DD, YYYY')}
                    galleryCount={photoCount}
                    linkUrl={webPath}
                    photographer={photographer}
                  />
                ))}
              </PhotoGalleriesGrid>
              {adSlots[index] && <MinuteMediaAd placement={adSlots[index]} />}
            </div>
          );
        })}
    </div>
  );
}
