import { SelectHTMLAttributes, ChangeEvent } from 'react';
import styles from './styles/SelectInput.module.scss';
import cn from 'classnames';

export enum SelectInputVariant {
  GRAY = 'Gray',
  WHITE = 'White'
}

export interface SelectInputProps<ValueType> extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'value' | 'onChange'> {
  value?: ValueType;
  onChange: (value?: ValueType) => void;
  options: (ValueType | [group: string, options: ValueType[]])[];
  valueExtractor?: (value?: ValueType) => string | undefined;
  labelExtractor?: (value: ValueType) => string;
  variant?: SelectInputVariant;
}

export default function SelectInput<ValueType>({
  className,
  placeholder,
  value,
  onChange,
  options,
  valueExtractor = value => `${value}`,
  labelExtractor = value => `${value}`,
  variant = SelectInputVariant.WHITE,
  ...props
}: SelectInputProps<ValueType>) {
  function handleChange(e: ChangeEvent<HTMLSelectElement>) {
    if (e.target.value) {
      const value = options
        .map(option => {
          if (Array.isArray(option)) {
            return option[1];
          } else {
            return [option];
          }
        })
        .flat()
        .find(option => valueExtractor(option) === e.target.value);

      onChange(value);
    } else {
      onChange(undefined);
    }
  }

  return (
    <select
      className={cn(styles.SelectInput, styles[variant], className)}
      onChange={handleChange}
      value={value === undefined ? '' : valueExtractor(value)}
      {...props}
    >
      {placeholder && <option value={valueExtractor(undefined)}>{placeholder}</option>}
      {options.map((option, index) => {
        if (Array.isArray(option)) {
          const [group, groupOptions] = option;

          return (
            <optgroup label={group} key={group}>
              {groupOptions.map(groupOption => (
                <option key={valueExtractor(groupOption)} value={valueExtractor(groupOption)}>
                  {labelExtractor(groupOption)}
                </option>
              ))}
            </optgroup>
          );
        } else {
          return (
            <option key={index} value={valueExtractor(option)}>
              {labelExtractor(option)}
            </option>
          );
        }
      })}
    </select>
  );
}
