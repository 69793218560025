import SelectInput, { SelectInputProps, SelectInputVariant } from '@/components/inputs/SelectInput';
import { useApplicationContainer } from 'containers/ApplicationContainer';

type FilterLinkProps<ValueType> = Omit<SelectInputProps<ValueType>, 'onChange' | 'valueExtractor' | 'variant'> & {
  pathExtractor: (option: ValueType) => string;
};

export default function Filter<ValueType>(props: SelectInputProps<ValueType> | Omit<FilterLinkProps<ValueType>, 'variant'>) {
  const [, { handleOpenLink }] = useApplicationContainer();

  if ('pathExtractor' in props) {
    const { pathExtractor, ...otherProps } = props;

    return (
      <SelectInput
        variant={SelectInputVariant.GRAY}
        valueExtractor={value => (value ? pathExtractor(value) : undefined)}
        onChange={value => handleOpenLink(pathExtractor(value!))}
        {...otherProps}
      />
    );
  } else {
    return <SelectInput variant={SelectInputVariant.GRAY} {...props} />;
  }
}
