import { useState } from 'react';
import { createContainer } from 'containers';
import { PhotoGalleriesIndexQuery, PhotoGalleriesIndexQueryVariables, rubyQueries } from 'queries';
import { selectTypename } from '@sblive-sports/api-client-js';
import usePagination from 'hooks/usePagination';
import { API_BASE_URL } from 'constants.js.erb';

export const PHOTO_GALLERIES = 'photos';
export const galleryPath = (webPath: string, photoIndex?: number) => {
  if (photoIndex !== undefined) {
    return `${webPath}?photoIndex=${photoIndex + 1}`;
  } else {
    return `${webPath}`;
  }
};

export interface PhotoGalleriesContainerProps {
  query: PhotoGalleriesIndexQuery;
  variables: PhotoGalleriesIndexQueryVariables;
}

// IDs to remove from the photographer filter, these correspond to test accounts
const PHOTOGRAPHER_IGNORE_LIST = API_BASE_URL === 'https://api.scorebooklive.com' ? ['1', '30', '31'] : [];

export const [PhotoGalleriesContainer, usePhotoGalleriesContainer] = createContainer(
  ({ query, variables }: PhotoGalleriesContainerProps) => {
    const [galleries, setGalleries] = useState(query.galleries.nodes.filter(selectTypename('ContentItemPhotoGallery')));
    const [galleryQuery, setGalleryQuery] = useState<string | undefined>(variables.query ?? undefined);

    const { handleLoadMore, hasNextPage } = usePagination({
      onLoadMore: ({ galleries: { nodes, pageInfo } }) => {
        setGalleries(galleries => [...galleries, ...nodes.filter(selectTypename('ContentItemPhotoGallery'))]);
        return pageInfo;
      },
      pageInfo: query.galleries.pageInfo,
      ttl: 60 * 5,
      query: rubyQueries.photoGalleriesIndexQuery,
      variables: { ...variables, paginating: true }
    });

    const collections = [
      { label: 'Just In', galleries: query.recent?.nodes, showAll: false },
      { label: 'Football Photos', galleries: query.football?.nodes, showAll: true },
      { label: 'Girls Volleyball Photos', galleries: query.girlsVolleyball?.nodes, showAll: true },
      { label: 'Flag Football Photos', galleries: query.flagFootall?.nodes, showAll: true },
      { label: 'Boys Basketball Photos', galleries: query.boysBasketball?.nodes, showAll: true },
      { label: 'Girls Basketball Photos', galleries: query.girlsBasketball?.nodes, showAll: true },
      { label: 'Baseball Photos', galleries: query.baseball?.nodes, showAll: true },
      { label: 'Softball Photos', galleries: query.softball?.nodes, showAll: true }
    ];

    return [
      {
        hasNextPage,
        galleries,
        variables,
        photographers: query.photographers.filter(p => !PHOTOGRAPHER_IGNORE_LIST.includes(p.id)),
        genderSports: query.genderSports,
        collections,
        galleryQuery
      },
      {
        handleLoadMore,
        setGalleryQuery
      }
    ];
  }
);
