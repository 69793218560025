import Page from '@/components/Page';
import { entryPoint } from 'react_rails/entryPoint';
import { PhotoGalleriesContainer, PhotoGalleriesContainerProps } from 'containers/PhotoGalleriesContainer';
import PhotoGallerySearch from 'containers/PhotoGalleriesContainer/components/PhotoGallerySearch';

export default entryPoint(function Index({ ...props }: PhotoGalleriesContainerProps) {
  return (
    <Page>
      <PhotoGalleriesContainer {...props}>
        <PhotoGallerySearch />
      </PhotoGalleriesContainer>
    </Page>
  );
});
