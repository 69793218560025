import Filter from '@/components/Filter';
import STATES from '../../../constants/States';
import { useApplicationContainer } from 'containers/ApplicationContainer';
import styles from './styles/PhotoGalleriesFilters.module.scss';
import { PHOTO_GALLERIES, usePhotoGalleriesContainer } from '..';
import dayjs from 'dayjs';

const PHOTOGRAPHER_QUERY_STRING_PARAM = 'photographerId';
const TIMEFRAME_QUERY_STRING_PARAM = 'afterDate';
const GALLERY_QUERY = 'q';
const SORT_QUERY_STRING_PARAM = 'orderDirection';

type PhotoFilterQueryStringParameterNames =
  | typeof PHOTOGRAPHER_QUERY_STRING_PARAM
  | typeof TIMEFRAME_QUERY_STRING_PARAM
  | typeof GALLERY_QUERY
  | typeof SORT_QUERY_STRING_PARAM;

interface Option {
  label: string;
  value: string;
}
const recentOptions: Option[] = [
  {
    label: 'Last Week',
    value: dayjs().subtract(1, 'week').format('YYYY-MM-DD')
  },
  {
    label: 'Last Month',
    value: dayjs().subtract(1, 'month').format('YYYY-MM-DD')
  }
];

const sortOptions: Option[] = [
  {
    label: 'Newest',
    value: 'DESC'
  },
  {
    label: 'Oldest',
    value: 'ASC'
  }
];

export default function PhotoGalleriesFilters() {
  const [{ state, genderSport }, { getCurrentQueryStringParam, makePath }] = useApplicationContainer();
  const [{ galleryQuery, genderSports, photographers }] = usePhotoGalleriesContainer();
  const photographerId = getCurrentQueryStringParam(PHOTOGRAPHER_QUERY_STRING_PARAM);
  const selectedPhotographer = photographers.find(photographer => photographer.id === photographerId);
  const timeframe = getCurrentQueryStringParam(TIMEFRAME_QUERY_STRING_PARAM);
  const selectedTimeframe = recentOptions.find(timeFilter => timeFilter.value === timeframe);
  const sortDirection = getCurrentQueryStringParam(SORT_QUERY_STRING_PARAM);
  const selectedSort = sortOptions.find(sortOption => sortOption.value === sortDirection);

  const updateOption = (optionName: 'state' | 'genderSport', optionValue: { slug: string } | null | false | string = null) => {
    return [
      { genderSport: genderSport || null, state: state || null, path: PHOTO_GALLERIES, [optionName]: optionValue },
      {
        ...(photographerId ? { [PHOTOGRAPHER_QUERY_STRING_PARAM]: photographerId } : {}),
        ...(galleryQuery !== undefined ? { [GALLERY_QUERY]: galleryQuery } : {}),
        ...(sortDirection ? { [SORT_QUERY_STRING_PARAM]: selectedSort?.value } : {}),
        ...(timeframe ? { [TIMEFRAME_QUERY_STRING_PARAM]: selectedTimeframe?.value } : {})
      }
    ];
  };

  const updateParam = (paramName: PhotoFilterQueryStringParameterNames, paramValue: string | null = null) => {
    return [
      { genderSport: genderSport || null, state: state || null, path: PHOTO_GALLERIES },
      {
        ...(photographerId ? { [PHOTOGRAPHER_QUERY_STRING_PARAM]: photographerId } : {}),
        ...(galleryQuery !== undefined ? { [GALLERY_QUERY]: galleryQuery } : {}),
        ...(sortDirection ? { [SORT_QUERY_STRING_PARAM]: selectedSort?.value } : {}),
        ...(timeframe ? { [TIMEFRAME_QUERY_STRING_PARAM]: selectedTimeframe?.value } : {}),
        [paramName]: paramValue
      }
    ];
  };

  return (
    <div className={styles.FiltersContainer}>
      <Filter
        options={recentOptions}
        value={selectedTimeframe}
        placeholder="Anytime"
        labelExtractor={when => when.label}
        pathExtractor={when => makePath(...updateParam(TIMEFRAME_QUERY_STRING_PARAM, when?.value))}
      />
      <Filter
        options={STATES.filter(state => state.isAmerican)}
        value={state}
        placeholder="All States"
        labelExtractor={state => state.name}
        pathExtractor={selectedState => makePath(...updateOption('state', selectedState))}
      />
      <Filter
        options={genderSports}
        placeholder="Sport"
        value={genderSport}
        labelExtractor={sport => sport.name}
        pathExtractor={selectedSport => makePath(...updateOption('genderSport', selectedSport))}
      />
      <Filter
        options={photographers}
        value={selectedPhotographer}
        placeholder="Photographers"
        labelExtractor={photog => `${photog.firstName} ${photog.lastName}`}
        pathExtractor={photog => makePath(...updateParam(PHOTOGRAPHER_QUERY_STRING_PARAM, photog?.id))}
      />
      <Filter
        options={sortOptions}
        value={selectedSort}
        placeholder="Sort By"
        labelExtractor={sort => sort.label}
        pathExtractor={sort => makePath(...updateParam(SORT_QUERY_STRING_PARAM, sort?.value))}
        className={styles.FilterSort}
      />
    </div>
  );
}
