import { CSSProperties } from 'react';
import { IconType } from '.';

interface AngleBracketRightProps extends IconType {
  style?: CSSProperties;
  direction?: 'right' | 'left' | 'down';
}

export default function AngleBracketRight({
  className = 'icon',
  color = '#3E89F7',
  direction = 'right',
  height = 6,
  style,
  width = 3
}: AngleBracketRightProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="6.818" height="12.494" viewBox="0 0 6.818 12.494">
      <path
        id="angle-down-regular"
        d="M28.23,177.394l-5.4,5.08a.767.767,0,0,1-.586.263.852.852,0,0,1-.586-.233l-5.4-5.109a.852.852,0,1,1,1.171-1.239l4.81,4.554,4.81-4.558a.851.851,0,0,1,1.2.033A.86.86,0,0,1,28.23,177.394Z"
        transform="translate(-175.917 28.495) rotate(-90)"
        fill={color}
      />
    </svg>
  );
}
