import { useEffect, RefObject } from 'react';

interface useInfiniteScrollParams {
  onEndReached: () => void;
  ref: RefObject<HTMLElement>;
}

export default function useInfiniteScroll({ onEndReached, ref }: useInfiniteScrollParams) {
  useEffect(() => {
    function handleScroll() {
      const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      const scrollHeight = ref.current?.scrollHeight;
      const clientHeight = document.documentElement.clientHeight || window.innerHeight;

      if (scrollHeight && Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
        onEndReached();
      }
    }

    handleScroll();

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, [onEndReached]);
}
