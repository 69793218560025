import { ChangeEvent, KeyboardEvent, useMemo, useState } from 'react';
import ContentSection, { ContentSectionFlushContent } from '@/components/ContentSection';
import Filter from '@/components/Filter';
import { SelectInputVariant } from '@/components/inputs/SelectInput';
import PageLayout from '@/components/PageLayout';
import PageHeader from '@/components/PageHeader';
import MinuteMediaAd from '@/components/ads/MinuteMediaAd';
import Search from 'icons/Search';
import Close from 'icons/Close';
import PhotographerNetwork from 'icons/PhotographyNetworkWhite';
import { useApplicationContainer } from 'containers/ApplicationContainer';
import { PHOTO_GALLERIES, usePhotoGalleriesContainer } from '..';
import styles from './styles/PhotoGallerySearch.module.scss';
import searchStyles from 'containers/SearchContainer/components/styles/SearchBox.module.scss';
import STATES from '../../../constants/States';
import PhotoGallerySportCollections from './PhotoGallerySportCollections';
import PhotoGalleriesInfiniteScroll from './PhotoGalleriesInfiniteScroll';
import PhotoGalleriesFilters from './PhotoGalleriesFilters';

export default function PhotoGallerySearch() {
  const [{ state, genderSport }, { getCurrentQueryStringParam, makePath, updateCurrentQueryStringParams, handleOpenLink }] =
    useApplicationContainer();
  const [{ galleryQuery, galleries, genderSports }] = usePhotoGalleriesContainer();

  const queryStringQuery = getCurrentQueryStringParam('q');
  const queryStringPhotographerId = getCurrentQueryStringParam('photographerId');
  const [localGalleryQuery, setLocalGalleryQuery] = useState<string | undefined>(queryStringQuery ?? '');

  const galleriesResultLabel = galleries.length === 1 ? 'Photo Gallery' : 'Photo Galleries';

  const handleChangeGallerySearch = (e: ChangeEvent<HTMLInputElement>) => {
    setLocalGalleryQuery(e.target.value);
  };

  const handleClearInput = () => {
    handleOpenLink(updateCurrentQueryStringParams({ q: '' }));
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleGallerySearch();
    }
  };

  const handleGallerySearch = () => {
    handleOpenLink(updateCurrentQueryStringParams({ q: localGalleryQuery }));
  };

  const EmptySearch = useMemo(() => {
    return (
      <>
        <div className={styles.SearchBackground}>
          <div className={styles.SearchTagline}>Discover The Biggest Moments</div>
          <div className={styles.SearchContainer}>
            <Filter
              options={STATES.filter(state => state.isAmerican)}
              value={state}
              placeholder="All States"
              labelExtractor={state => state.name}
              pathExtractor={selectedState =>
                makePath({ genderSport: genderSport || null, state: selectedState || null, path: PHOTO_GALLERIES })
              }
              variant={SelectInputVariant.WHITE}
              className={styles.SearchStates}
            />
            <Filter
              options={genderSports}
              placeholder="All Sports"
              value={genderSport}
              labelExtractor={sport => sport.name}
              pathExtractor={selectedSport => makePath({ genderSport: selectedSport || null, state: state || null, path: PHOTO_GALLERIES })}
              variant={SelectInputVariant.WHITE}
              className={styles.SearchSports}
            />
            <input
              type="text"
              autoComplete="off"
              name="search"
              onChange={handleChangeGallerySearch}
              onKeyDown={handleKeyDown}
              value={localGalleryQuery}
              placeholder="Search photo galleries"
              className={styles.SearchInput}
            />
            <div className={styles.SearchButton} onClick={handleGallerySearch}>
              <Search />
            </div>
          </div>
          <div className={styles.PhotographyNetworkIcon}>
            <PhotographerNetwork />
          </div>
        </div>
        <PageLayout>
          <ContentSection>
            {!genderSport && <PhotoGallerySportCollections />}
            {genderSport && <PhotoGalleriesInfiniteScroll />}
          </ContentSection>
        </PageLayout>
      </>
    );
  }, [state, genderSport, queryStringQuery, localGalleryQuery]);

  const SearchResults = useMemo(() => {
    return (
      <PageLayout>
        <ContentSection>
          <PageHeader>High School Sports Photos</PageHeader>
          <ContentSectionFlushContent>
            <div className={searchStyles.SearchText}>
              <input
                type="text"
                name="search"
                value={localGalleryQuery || ''}
                onChange={handleChangeGallerySearch}
                onKeyDown={handleKeyDown}
                placeholder="Search photo galleries"
              />
              {localGalleryQuery && localGalleryQuery !== '' && (
                <div onClick={handleClearInput} className={searchStyles.ClearText}>
                  <Close height={12} width={12} color="#000" />
                </div>
              )}
            </div>
            <PhotoGalleriesFilters />
            <div className={styles.SearchInfo}>
              {queryStringQuery !== '' && (
                <>
                  <div className={styles.SearchTerm}>Results for "{galleryQuery}"</div>
                  {galleries.length > 0 && (
                    <div className={styles.SearchResultCount}>
                      {galleries.length} {galleriesResultLabel}
                    </div>
                  )}
                </>
              )}
              {queryStringQuery === '' && <div className={styles.SearchTerm}>Showing all photo galleries</div>}
            </div>
            <PhotoGalleriesInfiniteScroll />
          </ContentSectionFlushContent>
        </ContentSection>
      </PageLayout>
    );
  }, [handleChangeGallerySearch, handleClearInput, localGalleryQuery]);

  return queryStringQuery !== null || queryStringPhotographerId !== null ? SearchResults : EmptySearch;
}
